<template>
  <div v-if="adminLoaded && multigroupsLoaded">
    <router-view />
  </div>
  <div v-else-if="adminLoaded && multigroups.length === 0" class="container-fluid mt-5">
    <div class="row justify-content-center align-items-center mt-5">
      <div class="col-12 text-center">
        <p>You currently have 0 {{ accountTypeText.plural }}.</p>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12 text-center">
        <button type="button" class="btn btn-primary" @click="navigateToCreate()">
          Create a {{ accountTypeText.singular }}
        </button>
      </div>
    </div>
  </div>
  <sp-loader v-else />
</template>

<script>
import SpLoader from '@/components/SPLoader';
import redirectMixin from '@/utils/mixins/Redirect';

export default {
  name: 'ClassesAdmin',
  components: {
    'sp-loader': SpLoader
  },
  mixins: [redirectMixin],
  data() {
    return {
      adminLoaded: false,
      multigroupsLoaded: false
    };
  },
  beforeRouteEnter(to, from, next) {
    // Wait for user to resolve to get settings jcervantes 12-23-2018
    next(vm => {
      if (!vm.$store.getters['user/getUser'] ||
        (vm.$store.getters['user/getUser'] && !vm.user.id) ||
        !vm.$store.getters['multigroup/getMultigroups'] ||
        (vm.$store.getters['multigroup/getMultigroups'] && !vm.multigroups.length) ) {
        vm.$store.dispatch('header/loadHeaderData').then(
          (response) => {
            // make sure only admins and master_admin or admin get to this components
            vm.adminLoaded = true;
            if (vm.$store.getters['user/isOrgAdmin']) {
              vm.adminLoaded = true;
              vm.$store.dispatch('multigroup/loadMultigroupData').then(
                (response) => {
                  if (vm.multigroups.length > 0 && vm.multigroups[0].id) {
                    if (vm.user.organization.role === 'master_admin') {
                      vm.multigroupsLoaded = true;
                      vm.$router.replace({
                        path: vm.resourceType() + vm.multigroups[0].id + '/users'
                      }).catch(err => {});
                    } else {
                      let multigroupid;
                      // find the first mg that the member has perms to and route them to it
                      for (let i = 0; i < vm.multigroups.length; i++) {
                        if (vm.multigroups[i].perms_count > 0) {
                          multigroupid = vm.multigroups[i].id;
                          break;
                        }
                      }
                      if (multigroupid) {
                        vm.multigroupsLoaded = true;
                        vm.$router.replace({
                          path: vm.resourceType() + multigroupid + '/users'
                        }).catch(err => {});
                      }
                    }
                  }
                },
                error => {
                  console.log(error);
                }
              );
            } else {
              // if not send user to dashboard
              vm.redirectUrl(process.env.SPENV.APP_URL + '/lmsapp/dashboard');
            }
          },
          error => {
            console.log(error);
          }
        );
      }
    });
  },
  computed: {
    accountTypeText () {
      let accountType = { 'singular': '', 'plural': '', 'admin': '' };
      if (this.user && _.has(this.user, 'organization.type')) {
        if (this.isAcademic) {
          accountType = { 'singular': 'Class', 'plural': 'Classes', 'admin': 'Instructor', 'organization': 'School' };
        } else {
          accountType = { 'singular': 'Group', 'plural': 'Groups', 'admin': 'Admin', 'organization': 'Team' };
        }
      }
      return accountType;
    },
    multigroups() {
      return this.$store.getters['multigroup/getMultigroups'];
    }
  },
  methods: {
    navigateToCreate () {
      this.redirectUrl(`${this.SPENV.WWW_URL}/${this.accountTypeText.plural}/create/path`);
    },
    resourceType (){
      let groupType = 'classes';
      if (this.isEnterprise) {
        groupType = 'groups';
      }
      return '/' + groupType + '/';
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
